import { useLocation } from "react-router-dom";
import { Divider, Header } from "@data-products-and-ai/react-components";
import "./news.css";
const News = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const str = queryParams.get("str") as string;
  const company = queryParams.get("company") as string;
  const heading = queryParams.get("heading") as string;

  return (
    <>
      <Header title="Full Story" template="tableau" />
      <div style={{ margin: 20, fontFamily: "Siemens" }}>
        {company || heading ? (
          <>
            <div style={{ fontSize: 30 }}>{company}</div>
            <div style={{ fontSize: 20 }}>{heading}</div>
            <Divider padding={20} />
          </>
        ) : (
          ""
        )}

        <div dangerouslySetInnerHTML={{ __html: str }} />
      </div>
    </>
  );
};

export default News;
