import "./App.css";

import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import FooterWrapper from "./components/Footer/Footer";
import HeaderWrapper from "./components/Header/Header";
import LatexWrapper from "./components/LatexToEquation/LatexToEquation";
import PressWrapper from "./components/SEWebsiteSearch/SEWebsiteSearch";
import QRCodeWrapper from "./components/QRCode/QRCode";

import MainComponent from "./components/tableau/Main";
import { Header } from "@data-products-and-ai/react-components";
import News from "./components/News/News";

function App() {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <div style={{ fontSize: 16 }}>
              <Header />
            </div>
          }
        />
        <Route path="/footer" element={<FooterWrapper />} />
        <Route path="/header" element={<HeaderWrapper />} />
        <Route path="/se_search" element={<PressWrapper />} />
        <Route path="/latex_to_equation" element={<LatexWrapper />} />
        <Route path="/qrcode" element={<QRCodeWrapper />} />
        <Route path="/news" element={<News />} />

        <Route path="/tableau" element={<MainComponent />} />
      </Routes>
    </Router>
  );
}

export default App;
